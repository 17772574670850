import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { Offline, Online } from 'react-detect-offline';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  name: {
    marginRight: '25px',
  },
}));

const AppHeader = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Inspection Manager
          </Typography>
          <div>
            <span className={classes.name}>{props.loggedInEmail}</span>
            <Online>
              <span title="Connected">
                <WifiIcon />
              </span>
            </Online>
            <Offline>
              <span title="No connection">
                <WifiOffIcon color="error" />
              </span>
            </Offline>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AppHeader;
