import { gql } from '@apollo/client';

export const CREATE_FINDING = gql`
  mutation(
    $checkListId: Int!
    $inspVisitId: Int!
    $locationId: Int!
    $findingTypeId: Int!
    $note: String
  ) {
    createInspFinding(
      input: {
        checkListId: $checkListId
        inspVisitId: $inspVisitId
        locationId: $locationId
        findingTypeId: $findingTypeId
        note: $note
      }
    ) {
      id
    }
  }
`;
