import { gql } from '@apollo/client';

export const COMMON_PRODUCTS = gql`
  query {
    inspProdTypes {
      nodes {
        id
        name
        categoryId
        category
        components {
          id
          name
          selectOptionDisplay
        }
      }
    }
  }
`;
