import { gql } from '@apollo/client';

export const CREATE_INSP_CHECKLISTS = gql`
  mutation(
    $schemaName: String!
    $tableName: String!
    $columns: [String!]
    $values: [String]
  ) {
    dynamicInsert(
      input: {
        schemaName: $schemaName
        tableName: $tableName
        columns: $columns
        values: $values
      }
    ) {
      success
      message
      id
    }
  }
`;
