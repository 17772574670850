import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import * as JSONPATH from 'jsonpath';
import { withStyles } from '@material-ui/core/styles';
import { VisitsTable, AppHeader } from './_components';
import { GET_USER_DETAILS } from './_queries/query';
import 'survey-react/survey.css';
import './App.css';
import Cookies from 'js-cookie';
import { decodeJWT } from './_helpers';
import SurveyCreator from './SurveyCreator';
import { LoginPage } from './LoginPage/LoginPage';
import InspectionChecklist from './InspectionChecklist';
const SSO_URL =
  process.env.REACT_APP_LOGIN_URL ||
  'https://sso.keystonecerts.com/auth/login/';

const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialog: {
    position: 'relative',
  },
});

const MESSAGE_SUCCESS = {
  title: 'Success',
  severity: 'success',
};

const MESSAGE_ERROR = {
  title: 'Error',
  severity: 'error',
};

const authToken = Cookies.get('auth._token.local');
let loggedInUserId = null;
let hasToken = false;
if (authToken) {
  loggedInUserId = decodeJWT(authToken).data.user;
  hasToken = true;
}

const App = () => {
  const [checklist, setChecklist] = useState({
    isViewingChecklist: false,
    checklistIdToView: null,
    isNewChecklist: false,
    isSubmitted: false,
  });
  const {
    loading: fetchingUser,
    error: fetchingUserError,
    data: loggedInUser,
  } = useQuery(GET_USER_DETAILS, {
    variables: { loggedInUserId },
  });

  const viewChecklist = (id, visitId, trackableId, custId, submitted) => {
    setChecklist({
      isViewingChecklist: true,
      checklistIdToView: id,
      visitId: visitId,
      trackableId: trackableId,
      custId: custId,
      isSubmitted: submitted,
      isNewChecklist: id === null ? true : false,
    });
  };

  const viewVisitList = () => {
    setChecklist({
      isViewingChecklist: false,
      checklistIdToView: null,
      visitId: null,
      trackableId: null,
      custId: null,
      isNewChecklist: false,
      isSubmitted: false,
    });
  };

  if (fetchingUser) return <p>Getting user details...</p>;
  if (fetchingUserError) return <p>Error getting your user details...</p>;
  if (!hasToken) return <LoginPage />;

  return (
    <div>
      <AppHeader loggedInEmail={loggedInUser.webUser.email} />
      {checklist.isViewingChecklist ? (
        <InspectionChecklist visitId={checklist.visitId} />
      ) : (
        <VisitsTable
          userId={loggedInUser.webUser.email}
          viewChecklist={viewChecklist}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(App);
