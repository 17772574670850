import React, { Component, isValidElement, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { useQuery } from '@apollo/client';
import { VISIT } from './_queries/query';
import {
  InspectionChecklistHeader,
  InspectionCheckistProgramFindings,
  InspectionChecklistSignatures,
  InspectionChecklistOgranizationFindings,
} from './_components';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      //width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Organization Findings', 'Program Findings', 'Review'];

function _renderStepContent(step, stepProps) {
  switch (step) {
    case 0:
      return <InspectionChecklistOgranizationFindings />;
    case 1:
      return (
        <InspectionCheckistProgramFindings
          visit={stepProps.visit}
          errors={stepProps.errors}
          touched={stepProps.touched}
          values={stepProps.values}
          setFieldValue={stepProps.setFieldValue}
        />
      );
    case 2:
      return <InspectionChecklistSignatures />;
    default:
      return <div>Not Found</div>;
  }
}

const InspectionChecklist = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  //get visit
  const { loading: loadingVisit, error: visitError, data: visit } = useQuery(
    VISIT,
    {
      variables: { id: props.visitId },
    },
  );

  if (loadingVisit)
    return <div>Loading visit with id {props.checklistIdToView}...</div>;

  let validationShape = {
    base: yup.object().shape({}),
    productSelect: yup.mixed().required('Product is required'),
    nfrcProductSelect: yup.mixed().required('NFRC product is required'),
    awsProducts: yup.lazy((value) => {
      if (value !== undefined) {
        return yup.array().of(
          yup.object().shape({
            awsProductSelect: yup.mixed().required('AWS product is required'),
          }),
        );
      }
      return yup.mixed().notRequired();
    }),
  };

  const ValidationSchema = yup.object().shape(validationShape);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div className={classes.layout}>
      <CssBaseline />
      <InspectionChecklistHeader
        inspectionOrganization={
          visit.inspVisit.inspRecords[0].inspOrgranization
        }
      />
      <Stepper activeStep={activeStep} className={classes.stepper} nonLinear>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <div style={{ padding: 20 }}>
        <Formik
          initialValues={{
            nfrcProductSelect: null,
            productSelect: null,
            awsProducts: [],
            productType: '1',
            components: [],
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              alert(JSON.stringify(values, null, 2));
            }, 500);
          }}
        >
          {({
            submitForm,
            isSubmitting,
            errors,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                {_renderStepContent(activeStep, {
                  visit: visit,
                  errors: errors,
                  touched: touched,
                  values: values,
                  setFieldValue: setFieldValue,
                })}

                <Grid item xs={12} sm={12}>
                  <Box margin={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default InspectionChecklist;
