import * as JSONPATH from 'jsonpath';
import React, { Component, isValidElement, useState } from 'react';
import './App.css';
import 'jquery-ui/themes/base/all.css';
import 'nouislider/distribute/nouislider.css';
import 'select2/dist/css/select2.css';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';
import 'jquery-bar-rating/dist/themes/css-stars.css';
import 'jquery-bar-rating/dist/themes/fontawesome-stars.css';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import * as widgets from 'surveyjs-widgets';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'select2/dist/js/select2.js';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  CHECKLIST_ANSWERS,
  SURVEY_TEMPLATE,
  ACTIVE_SURVEY_TEMPLATE,
  VISIT,
  COMMON_PRODUCTS,
  INSP_FINDING_TYPES,
} from './_queries/query';
import {
  CREATE_CHECKLIST,
  CREATE_FINDING,
  UPDATE_CHECKLIST,
  CREATE_INSP_CHECKLISTS,
  CREATE_FINDING_POLICY,
} from './_queries/mutate';
import { valueInjector } from './_helpers';
widgets.prettycheckbox(Survey);
widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);
widgets.microphone(Survey, $);

Survey.Serializer.addProperty('question', {
  name: 'nameOfColumnInInspChecklistsTableToSave:string',
  default: '',
  category: 'Save Inspection Update To',
});
Survey.Serializer.addProperty('question', {
  name: 'Inspection Table Column:string',
  category: 'Save Data To',
});
Survey.Serializer.addProperty('dropdown', {
  name: 'findingSection:dropdown',
  default: '',
  category: 'Findings Section',
});
Survey.Serializer.addProperty('dropdown', {
  name: 'hasFinding:boolean',
  default: false,
  category: 'Findings Section',
});
Survey.Serializer.addProperty('text', {
  name: 'queryResultLookupKey:string',
  default: '',
  category: 'Value from GraphQL Query',
});
Survey.Serializer.addProperty('boolean', {
  name: 'queryResultLookupKey:string',
  default: '',
  category: 'Value from GraphQL Query',
});
Survey.Serializer.addProperty('dropdown', {
  name: 'queryResultLookupKey:string',
  default: '',
  category: 'Choices from GraphQL Query',
});
Survey.StylesManager.applyTheme('modern');
Survey.sendResultOnPageNext = true;

const PRODUCT_CATEGORY_QUESTION_NAME = 'product-category-select';
const PRODUCT_SELECT_QUESTION_NAME = 'product-list-select';
const COMPONENTS_DYNAMIC_VALUE_NAME = 'components-dynamic-panel';
const COMPONENTS_SELECT_NAME = 'components-select';
const NFRC_PANEL_NAME = 'nfrc-program-dynamic-panel';
const FINDINGS_SELECT_NAME = 'findings-select';
const FINDING_DYNAMIC_PANEL_NAME = 'finding-dynamic-panel';
const SURVEY_TEMPLATE_TYPE_ID =
  Number(process.env.REACT_APP_SURVEY_TEMPLATE_ID) || 1;
let saveInterval;

let surveyBody = {};
const SurveyCreator = (props) => {
  const client = useApolloClient();
  let isNew = props.checklistIdToView === null ? true : false;
  let checklistId = props.checklistIdToView;
  /**
   * BEGIN QUERY DATA METHODS
   */
  // if not a new checklist, get answers
  const {
    loading: loadingAnswers,
    error: answerError,
    data: answers,
  } = useQuery(CHECKLIST_ANSWERS, {
    skip: isNew,
    variables: { id: props.checklistIdToView },
  });

  //if not a new checklist, get template returned from answers
  const {
    loading: loadingSurveyTemplate,
    error: surveyTemplateError,
    data: surveyTemplateUsedForChecklistAnswers,
  } = useQuery(SURVEY_TEMPLATE, {
    skip: answers === undefined,
    variables: { id: answers?.surveyResult.surveyTemplateId },
  });

  //if a new checklist, get latest template
  const {
    loading: loadingActiveSurveyTemplate,
    error: activeSurveyTemplateError,
    data: activeSurveyTemplate,
  } = useQuery(ACTIVE_SURVEY_TEMPLATE, {
    skip: !isNew,
    variables: { surveyTypeId: SURVEY_TEMPLATE_TYPE_ID },
  });

  //get visit
  const { loading: loadingVisit, error: visitError, data: visit } = useQuery(
    VISIT,
    {
      variables: { id: props.visitId },
    },
  );

  //get products
  const {
    loading: loadingCommonProducts,
    error: commonProductsError,
    data: commonProducts,
  } = useQuery(COMMON_PRODUCTS);

  //get inspection sections
  const {
    loading: loadingInspFindingTypes,
    error: inspFindingTypesError,
    data: inspFindingTypes,
  } = useQuery(INSP_FINDING_TYPES);

  /**
   * END QUERY DATA METHODS
   */

  /**
   * BEGIN MUTATION DATA METHODS
   */
  const saveChecklist = async (survey) => {
    const surveyAnswerData = JSON.stringify(survey.data);
    await client
      .mutate({
        mutation: CREATE_CHECKLIST,
        variables: {
          surveyData: surveyAnswerData,
          refId: visit.inspVisit.trackableId,
          refType: activeSurveyTemplate.surveys[0].surveytype.id,
          surveyTemplateId: activeSurveyTemplate.surveys[0].id,
          userId: 'bharris',
          submitted: false,
        },
      })
      .then((data) => {
        if (isNew) {
          isNew = false;
          checklistId = data.data.createSurveyResult.id;
        }
      });
  };

  const updateChecklist = async (survey, submitted) => {
    const surveyAnswerData = JSON.stringify(survey.data);
    await client
      .mutate({
        mutation: UPDATE_CHECKLIST,
        variables: {
          id: checklistId,
          surveyData: surveyAnswerData,
          userId: 'bharris',
          submitted: submitted,
        },
      })
      .then((data) => {});
  };

  const upsertChecklist = async (survey, submitted) => {
    if (!props.isSubmitted) {
      if (isNew) {
        await saveChecklist(survey);
      } else {
        await updateChecklist(survey, submitted);
      }
    }
  };

  const createFinding = async (finding, inspChecklistsId) => {
    return await client
      .mutate({
        mutation: CREATE_FINDING,
        variables: {
          checkListId: inspChecklistsId,
          inspVisitId: props.visitId,
          locationId: props.custId,
          findingTypeId: finding[`${FINDINGS_SELECT_NAME}`],
          note: finding[`${FINDINGS_SELECT_NAME}-Comment`],
        },
      })
      .then((data) => {
        return data.data.createInspFinding.id;
      });
  };

  const createFindingPolicy = async (findingId, programId) => {
    return await client
      .mutate({
        mutation: CREATE_FINDING_POLICY,
        variables: {
          findingId: findingId,
          programId: programId,
        },
      })
      .then((data) => {
        return data.data.createInspFindingPolicy.id;
      });
  };

  const createInspChecklists = async (columns, values) => {
    columns.push('vistID');
    values.push(props.visitId.toString());
    return await client
      .mutate({
        mutation: CREATE_INSP_CHECKLISTS,
        variables: {
          schemaName: 'kci_kdb',
          tableName: 'inspchecklists',
          columns: columns,
          values: values,
        },
      })
      .then((data) => {
        return data.data.dynamicInsert.id;
      });
  };
  /**
   * END MUTATION DATA METHODS
   */

  /**
   * BEGIN LOADING STATUS DISPLAY
   */

  if (loadingAnswers)
    return <div>Loading answers for {props.checklistIdToView}...</div>;
  if (loadingActiveSurveyTemplate)
    return (
      <div>Loading active survey template for {SURVEY_TEMPLATE_TYPE_ID}...</div>
    );
  if (loadingSurveyTemplate)
    return <div>Loading survey template used for answers...</div>;
  if (loadingCommonProducts) return <div>Loading common products...</div>;
  if (loadingVisit)
    return <div>Loading visit with id {props.checklistIdToView}...</div>;
  if (loadingInspFindingTypes)
    return <div>Loading inspection finding types...</div>;
  if (answerError)
    return <div>Error fetching answers for {props.checklistIdToView}...</div>;

  /**
   * END LOADING STATUS DISPLAY
   */

  /**
   * BEGIN MODIFY SURVEY TEMPLATE METHODS
   */
  surveyBody = isNew
    ? JSON.parse(activeSurveyTemplate.surveys[0].body)
    : JSON.parse(surveyTemplateUsedForChecklistAnswers.survey.body);

  //merge visit results
  valueInjector.injectValuesIntoSurveyBody(
    surveyBody,
    visit.inspVisit,
    'queryResultLookupKey',
  );

  //set up findings dropdowns
  JSONPATH.nodes(surveyBody, '$..*[?(@.name=="findings-select")]').forEach(
    (node) => {
      if (node.value.findingSection) {
        node.value.choices = inspFindingTypes.inspFindingTypes
          .filter(
            (findingType) => findingType.section === node.value.findingSection,
          )
          .map((found) => {
            return {
              value: found.id,
              text: found.title,
            };
          });
      }
    },
  );

  /**
   * END MODIFY SURVEY TEMPLATE METHODS
   */

  /**
   * BEGIN COMPONENT METHODS
   */
  const componentChoices = (selectedProduct) =>
    commonProducts.inspProdTypes.nodes
      .filter((f) => f.id === Number(selectedProduct))[0]
      ?.components.map((e) => {
        return {
          value: e.id,
          text: e.selectOptionDisplay,
        };
      });

  const productChoices = (productCategory) =>
    commonProducts.inspProdTypes.nodes
      .filter(
        (f) =>
          f.categoryId === Number(productCategory) && f.components.length > 0,
      )
      .map((v) => {
        return { text: v.name, value: v.id };
      });

  const buildNFRCPanelFromAnswers = (survey) => {
    survey.getAllQuestions().forEach((x) => {
      if (x.name === NFRC_PANEL_NAME) {
        x.panels.forEach((panel) => {
          const selectedProductCategory = panel
            .getPanel()
            .getQuestionByName(PRODUCT_CATEGORY_QUESTION_NAME).value;
          panel.getQuestionByName(
            PRODUCT_SELECT_QUESTION_NAME,
          ).choices = productChoices(selectedProductCategory);
          panel
            .getPanel()
            .getQuestionByName(COMPONENTS_DYNAMIC_VALUE_NAME)
            .panels.forEach((componentPanel) => {
              const selectedProduct = panel.getQuestionByName(
                PRODUCT_SELECT_QUESTION_NAME,
              ).value;
              componentPanel.getQuestionByName(
                COMPONENTS_SELECT_NAME,
              ).choices = componentChoices(selectedProduct);
            });
        });
      }
    });
  };
  /**
   * END COMPONENT METHODS
   */

  /**
   * BEGIN CUSTOM SURVEY HOOK IMPLEMENTATIONS
   */
  const onComplete = async (survey, options) => {
    clearInterval(saveInterval);
    let inspectionNonFindingId = await saveInspectionNonFindingUpdates(survey);
    await saveFindings(survey, inspectionNonFindingId);
    await upsertChecklist(survey, true);
    props.viewVisitList();
  };

  const saveInspectionNonFindingUpdates = async (survey) => {
    const columns = [];
    const values = [];
    survey.getAllQuestions().forEach((x) => {
      if (x.nameOfColumnInInspChecklistsTableToSave !== undefined) {
        columns.push(x.nameOfColumnInInspChecklistsTableToSave);
        values.push(x.value !== undefined ? x.value.toString() : '');
      }
    });
    const inspChecklistsId = await createInspChecklists(columns, values);
    return inspChecklistsId;
  };

  const saveFindings = async (survey, inspectionNonFindingId) => {
    const findings = [];
    JSONPATH.query(survey.data, '$..["finding-dynamic-panel"]').forEach(
      (node) => {
        node.forEach((x) => {
          createFinding(x, inspectionNonFindingId).then((findingId) => {
            if (x['findings-scope-radio']) {
              x['findings-scope-radio'].forEach((selectedRadio) => {
                createFindingPolicy(findingId, parseInt(selectedRadio, 10));
              });
            }
          });
        });
      },
    );
    return findings;
  };

  const onCurrentPageChanged = (survey, options) => {
    upsertChecklist(survey, false);
  };

  const onAfterRenderSurvey = (survey, options) => {
    const selector = document.getElementsByClassName('sv_nav')[0];
    var exitButton = document.createElement('input');
    exitButton.value = props.isSubmitted ? 'Exit' : 'Save and Exit';
    exitButton.type = 'button';
    exitButton.style.position = 'absolute';
    exitButton.style.right = '8%';
    exitButton.className = 'sv_next_btn';
    exitButton.onclick = () => saveProgressAndExitInspection(survey);
    //selector.append(exitButton);
    if (answers) {
      buildNFRCPanelFromAnswers(survey, productChoices, componentChoices);
    }
    saveInterval = window.setInterval(function() {
      upsertChecklist(survey, false);
    }, 10000);
  };

  const saveProgressAndExitInspection = async (survey) => {
    clearInterval(saveInterval);
    await upsertChecklist(survey, false);
    props.viewVisitList();
  };

  const onValueChanged = (sender, options) => {
    console.log(
      'value changed from ' +
        options.name +
        ' from index ' +
        options.question.currentIndexValue,
    );

    if (options.name === 'program-dynamic-panel') {
      // options.question.currentPanel.getQuestionByName('nfrc-products-select')
      //   .displayValue;
      // options.question.currentPanel.getQuestionByName('nfrc-products-select')
      //   .value;
      // options.question
      //   .getPanel()
      //   .getElementByName('findings-scope-radio')
      //   .choices.push({
      //     text: options.question.currentPanel.getQuestionByName(
      //       'nfrc-products-select',
      //     ).displayValue,
      //     value: options.question.currentPanel.getQuestionByName(
      //       'nfrc-products-select',
      //     ).value,
      //   });
    }
  };

  const onDynamicPanelAdded = (sender, options) => {
    // if (options.question.name === 'finding-dynamic-panel') {
    //   let programChoices = [];
    //   programChoices.push(
    //     options.question.parentQuestion.parentQuestion.getPlainData()
    //       .displayValue[
    //       options.question.parentQuestion.parentQuestion.currentIndexValue
    //     ]['NFRC Products'],
    //   );
    //   options.question.parentQuestion.parentQuestion
    //     .getPlainData()
    //     .displayValue[
    //       options.question.parentQuestion.parentQuestion.currentIndexValue
    //     ]['structural-dynamic-panel'].forEach((x) => {
    //       programChoices.push(x['Structural Options']);
    //     });
    //   // options.question.parentQuestionValue.parentQuestionValue.propertyHash.displayValue[0][
    //   //   'structural-dynamic-panel'
    //   // ].forEach((x) => {
    //   //   programChoices.push(x['structural-select']);
    //   // });
    //   // options.question.parentQuestionValue.parentQuestionValue.propertyHash.displayValue[0][
    //   //   'impact-dynamic-panel'
    //   // ].forEach((x) => {
    //   //   programChoices.push(x['impact-select']);
    //   // });
    //   options.question
    //     .getPanel()
    //     .getQuestionByName('findings-scope-radio').choices = programChoices;
    //   options.question
    //     .getPanel()
    //     .getQuestionByName('findings-scope-radio')
    //     .toggleSelectAll();
    // }
  };

  const onDynamicPanelItemValueChanged = (sender, options) => {
    if (!sender.isShowingPreview) {
      if (options.name === PRODUCT_CATEGORY_QUESTION_NAME) {
        options.panel.getQuestionByName(
          PRODUCT_SELECT_QUESTION_NAME,
        ).choices = productChoices(
          options.itemValue[PRODUCT_CATEGORY_QUESTION_NAME],
        );
      }

      if (options.name === PRODUCT_SELECT_QUESTION_NAME) {
        options.panel
          .getQuestionByName(COMPONENTS_DYNAMIC_VALUE_NAME)
          .clearValue();
        options.panel
          .getQuestionByName(COMPONENTS_DYNAMIC_VALUE_NAME)
          .getPanel()
          .getQuestionByName(COMPONENTS_SELECT_NAME).choices = componentChoices(
          options.value,
        );
      }

      if (options.name === 'nfrc-products-select') {
        console.log(
          'DEBUG ---> something changed with ' +
            options.name +
            ' at index ' +
            options.question.currentIndexValue,
        );

        options.panel
          .getQuestionByName('components-dynamic-panel')
          .getPanel()
          .getQuestionByName('finding-dynamic-panel')
          .getPanel()
          .getQuestionByName('findings-scope-radio')
          .choices.push(
            new Survey.ItemValue(
              options.question.displayValue[options.question.currentIndexValue][
                'NFRC Products'
              ],
            ),
          );
      }
    }
  };
  /**
   * END CUSTOM SURVEY HOOK IMPLEMENTATIONS
   */

  return (
    <React.Fragment>
      <Survey.Survey
        json={surveyBody}
        onComplete={onComplete}
        onCurrentPageChanged={onCurrentPageChanged}
        onAfterRenderSurvey={onAfterRenderSurvey}
        onValueChanged={onValueChanged}
        onDynamicPanelItemValueChanged={onDynamicPanelItemValueChanged}
        onDynamicPanelAdded={onDynamicPanelAdded}
        data={
          answers ? JSON.parse(answers?.surveyResult.surveyData) : undefined
        }
        mode={props.isSubmitted ? 'display' : undefined}
      />
    </React.Fragment>
  );
};

export default SurveyCreator;
