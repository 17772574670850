import { gql } from '@apollo/client';

export const CHECKLIST_ANSWERS = gql`
  query($id: Int!) {
    surveyResult(id: $id) {
      id
      surveyData
      surveyTemplateId
      refId
      refType
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
