import { gql } from '@apollo/client';

export const VISIT = gql`
  query($id: Int!) {
    inspVisit(id: $id) {
      id
      trackableId
      custId
      inspDate
      curStatus {
        statusCode
        statusType
        statusDesc {
          id
          statusDesc
        }
      }
      inspTrip
      inspTypes
      inspRecords {
        id
        assignedInspector
        completeInspBy
        inspType
        custId
        curStatus {
          statusCode
          statusDesc {
            statusDesc
          }
        }
        inspOrgranization {
          id
          kdbCustId
          companyName
          locationType
          address1
          address2
          city
          stateProvince
          postalCode
          country
          phone
          phone800
          fax
          nfrcProducts {
            id
            selectOptionDisplay
            opType
            activeNfrcComponents {
              id
              selectOptionDisplay
              opTypeCode
            }
          }
          awsProducts {
            id
            selectOptionDisplay
          }
          impactProducts {
            id
            modelName
            carNo
            opType
          }
          qaProducts {
            id
            model
            carNo
            type
          }
          hudProducts {
            id
            modelName
            carNo
            opType
          }
        }
      }
    }
  }
`;
