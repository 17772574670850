import { gql } from '@apollo/client';

export const GET_USER_DETAILS = gql`
  query($loggedInUserId: Int) {
    webUser(id: $loggedInUserId) {
      email
      id
      role
      active
      inspector
    }
  }
`;
