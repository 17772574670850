import { gql } from '@apollo/client';

export const LOAD_VISITS = gql`
  query($inspStatusCodes: [Int], $limit: Int) {
    inspVisits(matching: { inspStatusCodes: $inspStatusCodes }, limit: $limit) {
      totalCount
      nodes {
        id
        trackableId
        custId
        curStatus {
          statusCode
          statusType
          statusDesc {
            id
            statusDesc
          }
        }
        surveyResult {
          id
          surveyData
          surveyTemplateId
          refId
          refType
          createdBy
          createdAt
          updatedBy
          updatedAt
          submitted
        }
        inspTrip
        inspTypes
        inspRecords {
          id
          assignedInspector
          completeInspBy
          inspType
          custId
          curStatus {
            statusCode
            statusDesc {
              statusDesc
            }
          }
          inspOrgranization {
            id
            kdbCustId
            companyName
            locationType
            address1
            address2
            city
            stateProvince
            postalCode
            country
            phone
            phone800
            fax
          }
        }
      }
    }
  }
`;
