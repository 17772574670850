import React from "react";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    const SSO_URL =
      process.env.REACT_APP_LOGIN_URL ||
      "https://sso.keystonecerts.com/auth/login";
    const currentUrl = window.location.href;
    window.location.assign(SSO_URL);
  }

  render() {
    return <div>forwarding to login...</div>;
  }
}

export { LoginPage };
