import React from 'react';
import { Autocomplete } from 'formik-material-ui-lab';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';

const InspectionCheckistAutocomplete = (props) => {
  return props.index !== undefined ? (
    <Field
      name={`${props.belongsTo}.${props.index}.${props.name}`}
      component={Autocomplete}
      options={props.options}cmultiple
      getOptionLabel={(option) => option.title}
      getOptionSelected={(option, value) => option.title === value.title}
      style={{ width: props.width || null }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name + '.textfield'}
          helperText={
            props.errors &&
            props.errors[`${props.belongsTo}`] &&
            props.errors[`${props.belongsTo}`][`${props.index}`] &&
            props.errors[`${props.belongsTo}`][`${props.index}`][
              `${props.name}`
            ] &&
            props.touched &&
            props.touched[`${props.belongsTo}`] &&
            props.touched[`${props.belongsTo}`][`${props.index}`] &&
            props.touched[`${props.belongsTo}`][`${props.index}`][
              `${props.name}`
            ] && (
              <div className="field-error">
                {
                  props.errors[`${props.belongsTo}`][`${props.index}`][
                    `${props.name}`
                  ]
                }
              </div>
            )
          }
          error={
            props.errors &&
            props.errors[`${props.belongsTo}`] &&
            props.errors[`${props.belongsTo}`][`${props.index}`] &&
            props.errors[`${props.belongsTo}`][`${props.index}`][
              `${props.name}`
            ] &&
            props.touched &&
            props.touched[`${props.belongsTo}`] &&
            props.touched[`${props.belongsTo}`][`${props.index}`] &&
            props.touched[`${props.belongsTo}`][`${props.index}`][
              `${props.name}`
            ]
          }
          label={props.label || null}
          variant="outlined"
        />
      )}
    />
  ) : (
    <Field
      name={props.name}
      component={Autocomplete}
      options={props.options}
      getOptionLabel={(option) => option.title}
      getOptionSelected={(option, value) => option.title === value.title}
      style={{ width: props.width || null }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name + '.textfield'}
          error={
            props.touched[`${props.name}`] && !!props.errors[`${props.name}`]
          }
          helperText={
            props.touched[`${props.name}`] && props.errors[`${props.name}`]
          }
          label={props.label || null}
          variant="outlined"
        />
      )}
    />
  );
};

export default InspectionCheckistAutocomplete;
