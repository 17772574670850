import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
}));

const InspectionChecklistHeader = (props) => {
  const classes = useStyles();
  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
          <div>{props.inspectionOrganization.companyName}</div>
          <div>{props.inspectionOrganization.address1}</div>
          <div>{props.inspectionOrganization.address2}</div>
          <div>
            <span>
              {props.inspectionOrganization.city},{' '}
              {props.inspectionOrganization.stateProvince}{' '}
              {props.inspectionOrganization.postalCode}
            </span>
          </div>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default InspectionChecklistHeader;
