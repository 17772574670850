import React from 'react';
import { useQuery } from '@apollo/client';
import { DataGrid } from 'tubular-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { createColumn, ColumnSortDirection } from 'tubular-common';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as moment from 'moment';
import { LOAD_VISITS } from '../_queries/query';

const INSPECTION_STATUS_CODES = process.env.REACT_APP_VISIT_STATUS_CODES?.split(
  ',',
).map((x) => Number(x)) || [700, 600, 500];

const VisitsTable = (props) => {
  const {
    loading: isVisitsLoading,
    error: isVisitsError,
    data: visits,
  } = useQuery(LOAD_VISITS, {
    skip: props.userId === null,
    variables: {
      loggedInUserId: props.userId,
      inspStatusCodes: INSPECTION_STATUS_CODES,
      limit: 1000,
    },
    fetchPolicy: 'no-cache',
  });

  if (isVisitsLoading) return <p>Loading...</p>;
  if (isVisitsError) return <p>Error :(</p>;

  const formattedSurvey = visits.inspVisits.nodes.map(function(visit) {
    return {
      ...visit,
      visitLocation:
        visit.inspRecords.length !== 0 && visit.inspRecords[0].inspOrgranization
          ? {
              name: visit.inspRecords[0].inspOrgranization.companyName,
              address1: visit.inspRecords[0].inspOrgranization.address1,
              address2: visit.inspRecords[0].inspOrgranization.address2,
              city: visit.inspRecords[0].inspOrgranization.city,
              stateProvince:
                visit.inspRecords[0].inspOrgranization.stateProvince,
              postalCode: visit.inspRecords[0].inspOrgranization.postalCode,
              country: visit.inspRecords[0].inspOrgranization.country,
              phone: visit.inspRecords[0].inspOrgranization.phone,
              phone800: visit.inspRecords[0].inspOrgranization.phone888,
              fax: visit.inspRecords[0].inspOrgranization.fax,
            }
          : null,
      visitStatus: visit.curStatus.statusDesc.statusDesc,
      recordStatus:
        visit.inspRecords.length !== 0
          ? visit.inspRecords[0].curStatus.statusDesc.statusDesc
          : 'Unknown',
      completeBy:
        visit.inspRecords.length !== 0
          ? moment(visit.inspRecords[0].completeInspBy).format(
              'MMMM Do YYYY, h:mm a',
            )
          : 'Unknown',
      surveyResults: visit.surveyResult
        ? {
            surveyResultId: visit.surveyResult.id,
            surveyResultUpdatedAt: moment
              .utc(visit.surveyResult.updatedAt)
              .local(true)
              .fromNow(),
            surveryResultUpdatedBy: visit.surveyResult.updatedBy,
            submitted: visit.surveyResult.submitted,
          }
        : null,
      inspOrgId:
        visit.inspRecords.length !== 0 && visit.inspRecords[0].inspOrgranization
          ? visit.inspRecords[0].inspOrgranization.id
          : null,
    };
  });

  const columns = [
    createColumn('id', {
      visible: false,
      isKey: true,
    }),
    createColumn('trackableId', {
      visible: false,
    }),
    createColumn('inspOrgId', {
      visible: false,
    }),
    createColumn('surveyResults', {
      visible: false,
    }),
    createColumn('visitLocation', {
      filterable: true,
      label: 'Location',
      sortable: true,
      searchable: true,
    }),
    createColumn('inspTypes', {
      filterable: true,
      label: 'Type',
      sortable: true,
      searchable: true,
    }),
    createColumn('visitStatus', {
      filterable: true,
      label: 'Visit Status',
      sortable: true,
      searchable: true,
    }),
    createColumn('recordStatus', {
      filterable: true,
      label: 'Inspection Status',
      sortable: true,
      searchable: true,
    }),
    createColumn('completeBy', {
      filterable: true,
      label: 'Complete By',
      sortable: true,
      searchable: true,
      sortDirection: ColumnSortDirection.Descending,
      sortOrder: 1,
    }),
    createColumn('stagedColumn', {
      label: 'Last Updated',
      sortable: true,
      searchable: true,
    }),
  ];

  const CustomTbRow = ({ row }) => (
    <TableRow
      key={row.id}
      onClick={(e) =>
        !row.surveyResults
          ? props.viewChecklist(null, row.id, row.trackableId, row.inspOrgId)
          : props.viewChecklist(
              row.surveyResults.surveyResultId,
              row.id,
              row.trackableId,
              row.inspOrgId,
              row.surveyResults.submitted,
            )
      }
      hover={true}
    >
      {row.visitLocation && (
        <TableCell>
          <div>
            <strong>{row.visitLocation.name}</strong>
          </div>
          <div>{row.visitLocation.address1}</div>
          <div>{row.visitLocation.address2}</div>
          <div>
            {row.visitLocation.city}, {row.visitLocation.stateProvince}{' '}
            {row.visitLocation.country}
          </div>
          <div>
            phone: {row.visitLocation.phone}{' '}
            {row.visitLocation.phone800
              ? '| ' + row.visitLocation.phone800
              : ''}
          </div>
          <div>fax: {row.visitLocation.fax}</div>
        </TableCell>
      )}
      {!row.visitLocation && <TableCell>Unknown</TableCell>}
      <TableCell>{row.inspTypes}</TableCell>
      <TableCell>{row.visitStatus}</TableCell>
      <TableCell>{row.recordStatus}</TableCell>
      <TableCell>
        {row.surveyResults && row.surveyResults.submitted ? (
          <div title={'Inspection checklist submitted'}>
            <CheckCircleIcon style={{ color: 'green' }} fontSize="large" />
          </div>
        ) : (
          <div title={'Complete inspection by'}>{row.completeBy}</div>
        )}
      </TableCell>
      <TableCell>
        {row.surveyResults && (
          <div title={'Last updated'}>
            {row.surveyResults.surveyResultUpdatedAt}
          </div>
        )}
        {!row.surveyResults && (
          <div title={'Inspection not started'}>
            <WarningIcon style={{ color: 'orange' }} fontSize="large" />
          </div>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <DataGrid
      columns={columns}
      dataSource={formattedSurvey}
      gridName="Grid"
      rowComponent={CustomTbRow}
    ></DataGrid>
  );
};

export default VisitsTable;
