import React from 'react';
import { FieldArray } from 'formik';
import Box from '@material-ui/core/Box';
import InspectionCheckistAutocomplete from './InspectionChecklistAutocomplete';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
const InspectionChecklistAutocompleteFieldArray = (props) => {
  return (
    <FieldArray name={props.name}>
      {({ insert, remove, push }) => (
        <div>
          {props.fieldArrayValues.length > 0 &&
            props.fieldArrayValues.map((item, index) => (
              <div className="row" key={index}>
                <Box display="flex" align-items="flex-start">
                  <InspectionCheckistAutocomplete
                    name={props.autocompleteName}
                    options={props.autocompleteOptions}
                    label={props.autocompleteLabel}
                    touched={props.autocompleteTouched}
                    errors={props.autocompleteErrors}
                    index={index}
                    belongsTo={props.name}
                    width={'95%'}
                  />
                  <IconButton
                    type="button"
                    className="btn-danger"
                    onClick={() => remove(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </div>
            ))}
          <IconButton
            type="button"
            className="secondary"
            aria-label={props.addButtonText}
            onClick={() => push({ [props.autocompleteName]: null })}
          >
            <Add />
          </IconButton>
        </div>
      )}
    </FieldArray>
  );
};

export default InspectionChecklistAutocompleteFieldArray;
