import React from 'react';
import * as yup from 'yup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import { COMMON_PRODUCTS, INSP_FINDING_TYPES } from '../_queries/query';
import {
  InspectionCheckistAutocomplete,
  InspectionChecklistAutocompleteFieldArray,
} from './index';

const InspectionCheckistProgramFindings = (props) => {
  const {
    loading: loadingCommonProducts,
    error: commonProductsError,
    data: commonProducts,
  } = useQuery(COMMON_PRODUCTS);

  const {
    loading: loadingInspFindingTypes,
    error: inspFindingTypesError,
    data: inspFindingTypes,
  } = useQuery(INSP_FINDING_TYPES);

  if (loadingCommonProducts) return <div>Loading products...</div>;
  if (loadingInspFindingTypes)
    return <div>Loading inspection findings types...</div>;

  const nfrcOptions =
    props.visit.inspVisit.inspRecords[0].inspOrgranization.nfrcProducts.map(
      (x) => {
        return {
          title: x.selectOptionDisplay,
          value: x.id,
        };
      },
    ) || [];

  const awsOptions =
    props.visit.inspVisit.inspRecords[0].inspOrgranization.awsProducts.map(
      (x) => {
        return {
          title: x.selectOptionDisplay,
          value: x.id,
        };
      },
    ) || [];

  const productOptions =
    commonProducts.inspProdTypes.nodes
      .filter((x) => x.name !== null)
      .map((x) => {
        return {
          title: x.name,
          value: x.id,
          categoryId: x.categoryId,
        };
      }) || [];

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12} sm={6}>
        {nfrcOptions.length > 0 && (
          <FormControl component="fieldset" fullWidth={true} required={true}>
            <FormLabel component="legend">NFRC Product Selection</FormLabel>
            <InspectionCheckistAutocomplete
              name="nfrcProductSelect"
              options={nfrcOptions}
              touched={props.touched}
              errors={props.errors}
            />
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {awsOptions.length > 0 && (
          <FormControl component="div" fullWidth={true} required={true}>
            <FormLabel component="legend">AWS Product Selection</FormLabel>
            <InspectionChecklistAutocompleteFieldArray
              name="awsProducts"
              fieldArrayValues={props.values.awsProducts}
              autocompleteOptions={awsOptions}
              autocompleteName="awsProductSelect"
              autocompleteTouched={props.touched}
              autocompleteErrors={props.errors}
              addButtonText="Add AWS Product"
            />
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl component="div" required={true}>
          <FormLabel component="legend">Product Type</FormLabel>
          <RadioGroup
            aria-label="None"
            name="productType"
            value={props.values.productType?.toString()}
            onChange={(event) => {
              props.setFieldValue('productType', event.currentTarget.value);
              props.setFieldValue('productSelect', null);
            }}
            row
          >
            <FormControlLabel value="1" control={<Radio />} label="Fen" />
            <FormControlLabel value="2" control={<Radio />} label="Non-fen" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="div" fullWidth={true} required={true}>
          <FormLabel component="legend">Product Selection</FormLabel>
          <InspectionCheckistAutocomplete
            name="productSelect"
            options={productOptions.filter(
              (x) =>
                x.categoryId.toString() ===
                props.values.productType?.toString(),
            )}
            touched={props.touched}
            errors={props.errors}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        {props.values.productSelect !== null && (
          <FormControl component="div" fullWidth={true} required={true}>
            <FormLabel component="legend">Component Selection</FormLabel>
            <InspectionChecklistAutocompleteFieldArray
              name="components"
              fieldArrayValues={props.values.components}
              autocompleteOptions={awsOptions}
              autocompleteName="componentSelect"
              autocompleteTouched={props.touched}
              autocompleteErrors={props.errors}
              addButtonText="Add Component"
            />
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};

export default InspectionCheckistProgramFindings;
