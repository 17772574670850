import { gql } from '@apollo/client';

export const CREATE_CHECKLIST = gql`
  mutation(
    $surveyTemplateId: Int!
    $refId: String!
    $refType: Int!
    $userId: String!
    $surveyData: String
  ) {
    createSurveyResult(
      input: {
        surveyTemplateId: $surveyTemplateId
        surveyData: $surveyData
        refId: $refId
        refType: $refType
        userId: $userId
      }
    ) {
      id
      surveyTemplateId
      surveyData
      createdBy
      createdAt
      updatedBy
      updatedAt
      submitted
      inspVisit {
        id
        trackableId
        custId
        inspDate
      }
    }
  }
`;

export const UPDATE_CHECKLIST = gql`
  mutation(
    $id: Int!
    $surveyData: String!
    $userId: String!
    $submitted: Boolean
  ) {
    updateSurveyResult(
      input: {
        id: $id
        surveyData: $surveyData
        userId: $userId
        submitted: $submitted
      }
    ) {
      id
      surveyTemplateId
      surveyData
      createdBy
      createdAt
      updatedBy
      updatedAt
      submitted
    }
  }
`;
