import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { render } from 'react-dom';
import Cookies from 'js-cookie';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const graphQLUri =
  process.env.REACT_APP_KCI_GRAPHQL_URL ||
  'https://graphql.keystonecerts.com/graphql';

const httpLink = createHttpLink({
  uri: graphQLUri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get('auth._token.local');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  //shouldBatch: true,
});

const ApolloApp = (AppComponent) => (
  <ApolloProvider client={client}>
    <AppComponent client={client} />
  </ApolloProvider>
);

ReactDOM.render(ApolloApp(App), document.getElementById('root'));
registerServiceWorker();
