import { gql } from '@apollo/client';

export const INSP_FINDING_TYPES = gql`
  query {
    inspFindingTypes {
      id
      description
      title
      section
      category
      explanation
      requirement
    }
  }
`;
