import { gql } from '@apollo/client';

export const CREATE_FINDING_POLICY = gql`
  mutation($findingId: Int!, $programId: Int!) {
    createInspFindingPolicy(
      input: { findingId: $findingId, programId: $programId }
    ) {
      id
    }
  }
`;
