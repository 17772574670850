import { gql } from '@apollo/client';

export const ACTIVE_SURVEY_TEMPLATE = gql`
  query($surveyTypeId: Int!) {
    surveys(matching: { surveyTypeId: $surveyTypeId, active: true }) {
      id
      title
      description
      surveytype {
        id
        name
      }
      body
      createdBy
      createdAt
      updatedBy
      updatedAt
      active
      version
    }
  }
`;
